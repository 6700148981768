import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <h1>Contact</h1>
    <h2>Isaac Gierard</h2>
    <p>
      <strong>Email:</strong>
      <br />
      My first name at my last name dot com
    </p>
    <section className="online-contacts">
      <p>
        <strong>LinkedIn</strong>
        <br />
        <a href="https://www.linkedin.com/in/igierard">igierard</a>
      </p>

      <p>
        <strong>Twitter</strong>
        <br />
        <a href="https://twitter.com/igierard">igierard</a>
      </p>

      <p>
        <strong>Github</strong>
        <br />
        <a href="https://github.com/igierard">igierard</a>
      </p>
    </section>
    <p>
      <strong>Phone:</strong>
      <br />
      412.606.7029
    </p>
  </Layout>
)

export default ContactPage
